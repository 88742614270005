<template>
<div class="section">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>

</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees, grayscale, rgb } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../store/UserStore"
import axios from 'axios'

export default {
    props: {
        isShowPDFDialog: Boolean,
        shiftDate: Date,
        hideZeroShift: Boolean
    },

    data() {
        return {
            pdfDoc: {},
            helveticaFont: {},
            dailyShifts: [],
            shiftDateString: null,
            employees: [],
            groupedInventory: [],
            fuelInventory: [],
            user: {},
            pageNumber: 0,
            isShowPDFDialogLocal: false,
            page: {},
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'})
        }

    },

    methods: {

        fetchStoreShiftSales() {
            if (UserStore.getters.getActiveStore) {

                this.dailyShifts = []
                this.isLoading = true

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/shifts?date=" + this.shiftDateString
                
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.dailyShifts = response.data.data
                        this.removeZeroShift()
                        for (var i=0; i<this.dailyShifts.length; i++) {

                            if (this.dailyShifts[i].cigSales == null) {
                                this.dailyShifts[i].cigSales = {
                                    "buyAmount": 0,
                                    "buyCount": 0,
                                    "closeCount": 0,
                                    "openCount": 0
                                }
                            }

                        }
                        this.isShowPDFDialogLocal = true
                        this.createPdf(this)
                        this.pageNumber = 0  

                    })
                    .catch( (err) => {
                        this.dailyShifts = []
                         if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        if (err.response.status === 404) {
                            this.$buefy.toast.open({
                                message: 'No shift data found for ' + this.shiftDate.toLocaleDateString(),
                                type: 'is-warning'
                            })
                        }                        
                        UserStore.commit("setIsEditing",false)
                        this.isLoading = false

                    })
            }
            
        },


        removeZeroShift() {

            if ( this.hideZeroShift == true) {
                this.dailyShifts = this.dailyShifts.filter(tab => (tab.overallGrand != 0.00 && tab.overallGrand != null && tab.netSales != 0.00 && tab.netSales != null));
            }            
            
            this.activeTab = this.dailyShifts.length - 1
        },        

        fetchEmployees() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/employees'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.employees = this.sortArrayByProp(response.data.data,"firstName")
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        async fetchFuelInventory() {
            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/tanks/fuelReconciliation?product=A'
                            + '&fromDate=' + this.shiftDateString
                            + '&toDate=' + this.shiftDateString

            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                    this.fuelInventory = response.data.data
                    this.groupByProduct()

                    this.isLoading = false
                })
                .catch( (err) => {
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                    this.isLoading = false
                    if (err.response) {
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    } else {
                        console.log(err)
                    }
                    
                })
            
        },

        groupByProduct() {
            this.groupedInventory =this.groupArrayByProp(this.fuelInventory, "product")
        },

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        netClosingVolume(row) {
            return row.startingVolume + row.deliveredVolume - row.soldVolume
        },  

        netClosingVolumePOS(row) {
            return row.beginningVolumePOS + row.deliveredVolumePOS - row.soldVolume
        },

        varianceATG(row) {
            return row.endingVolume - this.netClosingVolume(row)
        },

        variancePOS(row) {
            return row.endingVolumePOS - this.netClosingVolumePOS(row)
        },

        createPdf: async(gthis) => {

            gthis.pdfDoc = await PDFDocument.create()
            // const pdfDoc = gthis.pdfDoc
            gthis.pdfDoc.setAuthor('VEECLi Inc. All rights reserved.')
            gthis.pdfDoc.setTitle('Daily Sales Summary')
            gthis.pdfDoc.setSubject('support@veecli.com')
            gthis.pdfDoc.setCreator('VEECLi Inc - www.veecli.com')
            gthis.pdfDoc.setProducer('VEECLi Backoffice Software')

            gthis.helveticaFont = await gthis.pdfDoc.embedFont(StandardFonts.Helvetica)

            var text = ""
            var row = 710
            var shiftColSpace = 240
            var totalNetLottery = 0
            var instantLotteryArray = []

            await gthis.pageHeader(gthis)

            gthis.drawTextLeft(gthis, row-15, 30, 10, "Employee")
            // await gthis.drawLine(gthis, row-22)
            gthis.drawTextLeft(gthis, row-50, 30, 10, "Merch Sales")
            await gthis.drawLine(gthis, row-52)
            gthis.drawTextLeft(gthis, row-70, 30, 10, "Fuel Sales")
            await gthis.drawLine(gthis, row-72)
            gthis.drawTextLeft(gthis, row-90, 30, 10, "Other")
            gthis.drawTextLeft(gthis, row-110, 30, 10, "Net Sales")
            gthis.drawTotalRectangle(gthis, row-110, 30, 550, 16, 0.1)

            
            gthis.drawTextLeft(gthis, row-150, 30, 10, "Cash")
            await gthis.drawLine(gthis, row-152)
            gthis.drawTextLeft(gthis, row-170, 30, 10, "Credit Card")
            await gthis.drawLine(gthis, row-172)            
            gthis.drawTextLeft(gthis, row-190, 30, 10, "Debit Card")
            await gthis.drawLine(gthis, row-192)
            gthis.drawTextLeft(gthis, row-210, 30, 10, "EBT")
            await gthis.drawLine(gthis, row-212)
            gthis.drawTextLeft(gthis, row-230, 30, 10, "Other")
            gthis.drawTextLeft(gthis, row-250, 30, 10, "Total Payments")
            gthis.drawTotalRectangle(gthis, row-250, 30, 550, 16, 0.1)


            gthis.drawTextLeft(gthis, row-290, 30, 10, "Instant Lottery Sales")
            await gthis.drawLine(gthis, row-292)
            gthis.drawTextLeft(gthis, row-310, 30, 10, "Instant Lottery Payout")
            await gthis.drawLine(gthis, row-312)
            gthis.drawTextLeft(gthis, row-330, 30, 10, "Online Lotto Sales")
            await gthis.drawLine(gthis, row-332)
            gthis.drawTextLeft(gthis, row-350, 30, 10, "Online Lotto Cancel")
            await gthis.drawLine(gthis, row-352)
            gthis.drawTextLeft(gthis, row-370, 30, 10, "Online Lotto Payout")
            await gthis.drawLine(gthis, row-372)
            gthis.drawTextLeft(gthis, row-390, 30, 10, "Machine Instant Lottery Sales")
            await gthis.drawLine(gthis, row-392)
            gthis.drawTextLeft(gthis, row-410, 30, 10, "Non-Cash Lottery Sales")
            await gthis.drawLine(gthis, row-412)
            gthis.drawTextLeft(gthis, row-430, 30, 10, "Net Lottery Sales")
            gthis.drawTotalRectangle(gthis, row-430, 30, 550, 16, 0.1)
            gthis.drawTextLeft(gthis, row-450, 30, 10, "Machine Lottery Cash Taken")

            gthis.drawTextLeft(gthis, row-500, 30, 10, "Cigarette Open Count")
            await gthis.drawLine(gthis, row-502)
            gthis.drawTextLeft(gthis, row-520, 30, 10, "Cigarette Buy Count")
            await gthis.drawLine(gthis, row-522)
            gthis.drawTextLeft(gthis, row-540, 30, 10, "Cigarette Sales Count")
            await gthis.drawLine(gthis, row-542)
            gthis.drawTextLeft(gthis, row-560, 30, 10, "Cigarette Balance Count")
            await gthis.drawLine(gthis, row-562)
            gthis.drawTextLeft(gthis, row-580, 30, 10, "Cigarette Close Count")
            gthis.drawTextLeft(gthis, row-600, 30, 10, "Variance")
            gthis.drawTotalRectangle(gthis, row-602, 30, 560, 16, 0.1)
            gthis.drawTextLeft(gthis, row-620, 30, 10, "Cigarette Sales Amount")
            await gthis.drawLine(gthis, row-622)
            gthis.drawTextLeft(gthis, row-640, 30, 10, "Cigarette Purchase Amount")

            var shiftCol = 80

            gthis.dailyShifts.forEach((element,index) => {

                shiftCol = index  * 85 + shiftColSpace

                text = "Shift " + (index + 1).toString()
                gthis.drawColHeadRight(gthis, row, shiftCol, 11, text)
                var empName = "N/A"
                if (element.employeeId != null) {
                    var employee = gthis.employees.find(o => o.id === element.employeeId)
                    empName=gthis.toTitleCase((employee.firstName+" "+employee.lastName.substring(0, 1)))
                }

                gthis.drawTextRight(gthis, row-15, shiftCol, 10, empName)
                gthis.drawColHeadLeft(gthis, row-37, 30, 10, "SALES SUMMARY") 
                gthis.drawTextRightCurrency(gthis, row-50, shiftCol, 10, element.merchSales) 
                gthis.drawTextRightCurrency(gthis, row-70, shiftCol, 10, element.fuelSales) 
                gthis.drawTextRightCurrency(gthis, row-90, shiftCol, 10, element.netSales - element.fuelSales - element.merchSales) 
                gthis.drawTextRightCurrency(gthis, row-110, shiftCol, 10, element.netSales) 

                gthis.drawColHeadLeft(gthis, row-137, 30, 10, "PAYMENT SUMMARY")
                gthis.drawTextRightCurrency(gthis, row-150, shiftCol, 10, element.cashAmount) 
                gthis.drawTextRightCurrency(gthis, row-170, shiftCol, 10, (element.creditAmount))
                gthis.drawTextRightCurrency(gthis, row-190, shiftCol, 10, (element.debitAmount))
                gthis.drawTextRightCurrency(gthis, row-210, shiftCol, 10, (element.foodstampAmount +  element.linkCardAmount)) 
                element.otherPayment = element.netSales - element.cashAmount - element.debitAmount - element.creditAmount - element.foodstampAmount - element.linkCardAmount
                gthis.drawTextRightCurrency(gthis, row-230, shiftCol, 10, element.otherPayment) 
                gthis.drawTextRightCurrency(gthis, row-250, shiftCol, 10, element.netSales)

                var netLotterySales = element.instantLottoSales - 
                                element.instantLottoPayout + 
                                element.onlineLottoSales - 
                                element.onlineLotteryCanceled -
                                element.onlineLottoPayout +
                                element.lotteryMachineWithdraw -
                                element.lotteryNonCashSales

                totalNetLottery += netLotterySales

                gthis.drawColHeadLeft(gthis, row-277, 30, 10, "LOTTERY SALES SUMMARY") 
                gthis.drawTextRightCurrency(gthis, row-290, shiftCol, 10, element.instantLottoSales) 
                gthis.drawTextRightCurrency(gthis, row-310, shiftCol, 10, element.instantLottoPayout * -1) 
                gthis.drawTextRightCurrency(gthis, row-330, shiftCol, 10, element.onlineLottoSales)
                gthis.drawTextRightCurrency(gthis, row-350, shiftCol, 10, element.onlineLotteryCanceled * -1)
                gthis.drawTextRightCurrency(gthis, row-370, shiftCol, 10, element.onlineLottoPayout * -1) 
                gthis.drawTextRightCurrency(gthis, row-390, shiftCol, 10, element.lotteryMachineWithdraw) 
                gthis.drawTextRightCurrency(gthis, row-410, shiftCol, 10, element.lotteryNonCashSales * -1) 
                gthis.drawTextRightCurrency(gthis, row-430, shiftCol, 10, netLotterySales) 
                gthis.drawTextRightCurrency(gthis, row-450, shiftCol, 10, element.lotteryMachineSold) 

                gthis.drawColHeadLeft(gthis, row-485, 30, 10, "CIGARETTE COUNT RECONCILIATION") 
                if (element.previousCigSales == null) {
                    element.previousCigSales = {
                        "closeCount": 0.00
                    }
                }
                gthis.drawTextRight(gthis, row-500, shiftCol, 10, (element.previousCigSales.closeCount ? element.previousCigSales.closeCount : 0)) 
                gthis.drawTextRight(gthis, row-520, shiftCol, 10, element.cigSales.buyCount) 
                gthis.drawTextRight(gthis, row-540, shiftCol, 10, element.cigaretteSaleCount * -1) 
                element.balanceCount = element.previousCigSales.closeCount + 
                                        element.cigSales.buyCount - 
                                        element.cigaretteSaleCount
                gthis.drawTextRight(gthis, row-560, shiftCol, 10, element.balanceCount) 
                gthis.drawTextRight(gthis, row-580, shiftCol, 10, element.cigSales.closeCount) 

                element.cigaretteVariance = element.cigSales.closeCount - element.balanceCount
                gthis.drawTextRight(gthis, row-600, shiftCol, 10, element.cigaretteVariance) 
                gthis.drawTextRightCurrency(gthis, row-620, shiftCol, 10, element.cigaretteSaleAmount) 
                gthis.drawTextRightCurrency(gthis, row-640, shiftCol, 10, element.cigaretteBuyAmount) 



                for (const item of element.lotteryBooksOnSale) {

                    var instantIndex = instantLotteryArray.findIndex(x => (x.bookNumber == item.bookNumber && x.gameId === item.gameId))

                    if (instantIndex == -1) {
                        instantLotteryArray.push({
                            "bin": item.saleRackLoc,
                            "gameId": item.gameId,
                            "gameNumber": item.gameNumber,
                            "bookNumber": item.bookNumber,
                            "gameName": item.name,
                            "saleRackLoc": item.saleRackLoc,
                            "pricePoint": item.pricePoint,
                            "startingSeq": {
                                "1": 0,
                                "2": 0,
                                "3": 0,
                                "4": 0,
                                "5": 0
                            },
                            "endingSeq": {
                                "1": 0,
                                "2": 0,
                                "3": 0,
                                "4": 0,
                                "5": 0
                            },
                            "ticketsSold": {
                                "1": 0,
                                "2": 0,
                                "3": 0,
                                "4": 0,
                                "5": 0
                            }
                        })
                        instantLotteryArray[instantLotteryArray.length-1].ticketsSold[index] = (item.lotteryBookSale.ticketsSold ? item.lotteryBookSale.ticketsSold : 0)
                        instantLotteryArray[instantLotteryArray.length-1].startingSeq[index] = (item.lotteryBookSale.startingSeq ? item.lotteryBookSale.startingSeq : 0)
                        if (item.lotteryBookSale.ticketsSold > 0) {
                            instantLotteryArray[instantLotteryArray.length-1].endingSeq[index] = (item.lotteryBookSale.endingSeq ? item.lotteryBookSale.endingSeq : 0)
                        } else {
                            instantLotteryArray[instantLotteryArray.length-1].endingSeq[index] = (item.lotteryBookSale.startingSeq ? item.lotteryBookSale.startingSeq : 0)                            
                        }
                    } else {
                        instantLotteryArray[instantIndex].ticketsSold[index] = (item.lotteryBookSale.ticketsSold ? item.lotteryBookSale.ticketsSold : 0) 
                        instantLotteryArray[instantIndex].startingSeq[index] = (item.lotteryBookSale.startingSeq ? item.lotteryBookSale.startingSeq : 0)
                        if (item.lotteryBookSale.ticketsSold > 0) {
                            instantLotteryArray[instantIndex].endingSeq[index] = (item.lotteryBookSale.endingSeq ? item.lotteryBookSale.endingSeq : 0)
                        } else {
                            instantLotteryArray[instantIndex].endingSeq[index] = (item.lotteryBookSale.startingSeq ? item.lotteryBookSale.startingSeq : 0)                            
                        }                    }
                    
                }

                instantLotteryArray = gthis.sortArrayByProp(instantLotteryArray, "saleRackLoc")
                
            })

            // Totals Column
            var totalCol = 570
            text = "Total"
            gthis.drawColHeadRight(gthis, row, totalCol, 11, text)

            var totalMerchSales = gthis.dailyShifts.map(item => item.merchSales).reduce((prev, next) => prev + next)
            var totalFuelSales = gthis.dailyShifts.map(item => item.fuelSales).reduce((prev, next) => prev + next)
            var totalNetSales = gthis.dailyShifts.map(item => item.netSales).reduce((prev, next) => prev + next)
            var totalOtherSales = totalNetSales - totalMerchSales - totalFuelSales

            gthis.drawTextRightCurrency(gthis, row-50, totalCol, 10, totalMerchSales)
            gthis.drawTextRightCurrency(gthis, row-70, totalCol, 10, totalFuelSales)
            gthis.drawTextRightCurrency(gthis, row-90, totalCol, 10, totalOtherSales)
            gthis.drawTextRightCurrency(gthis, row-110, totalCol, 10, totalNetSales) 

            
            gthis.drawTextRightCurrency(gthis, row-150, totalCol, 10, gthis.dailyShifts.map(item => item.cashAmount).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-170, totalCol, 10, gthis.dailyShifts.map(item => item.creditAmount).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-190, totalCol, 10, gthis.dailyShifts.map(item => item.debitAmount).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-210, totalCol, 10, gthis.dailyShifts.map(item => item.foodstampAmount + item.linkCardAmount).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-230, totalCol, 10, gthis.dailyShifts.map(item => item.otherPayment).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-250, totalCol, 10, gthis.dailyShifts.map(item => item.netSales).reduce((prev, next) => prev + next))

            gthis.drawTextRightCurrency(gthis, row-290, totalCol, 10, gthis.dailyShifts.map(item => item.instantLottoSales).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-310, totalCol, 10, gthis.dailyShifts.map(item => item.instantLottoPayout * -1).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-330, totalCol, 10, gthis.dailyShifts.map(item => item.onlineLottoSales).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-350, totalCol, 10, gthis.dailyShifts.map(item => item.onlineLotteryCanceled * -1).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-370, totalCol, 10, gthis.dailyShifts.map(item => item.onlineLottoPayout * -1).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-390, totalCol, 10, gthis.dailyShifts.map(item => item.lotteryMachineWithdraw).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-410, totalCol, 10, gthis.dailyShifts.map(item => item.lotteryNonCashSales * -1).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-430, totalCol, 10, totalNetLottery)
            gthis.drawTextRightCurrency(gthis, row-450, totalCol, 10, gthis.dailyShifts.map(item => item.lotteryMachineSold).reduce((prev, next) => prev + next))

            var cigTotalBuy = gthis.dailyShifts.map(item => item.cigSales.buyCount).reduce((prev, next) => prev + next)
            var cigTotalSold = gthis.dailyShifts.map(item => item.cigaretteSaleCount).reduce((prev, next) => prev + next)
            var cigTotalBalance = gthis.dailyShifts[0].previousCigSales.closeCount + cigTotalBuy - cigTotalSold
            var cigTotalVariance = gthis.dailyShifts[gthis.dailyShifts.length-1].cigSales.closeCount - cigTotalBalance
            gthis.drawTextRight(gthis, row-500, totalCol, 10, (gthis.dailyShifts[0].previousCigSales.closeCount ? gthis.dailyShifts[0].previousCigSales.closeCount : 0))
            gthis.drawTextRight(gthis, row-520, totalCol, 10, cigTotalBuy)
            gthis.drawTextRight(gthis, row-540, totalCol, 10, cigTotalSold * -1)

            gthis.drawTextRight(gthis, row-560, totalCol, 10, cigTotalBalance)
            gthis.drawTextRight(gthis, row-580, totalCol, 10, gthis.dailyShifts[gthis.dailyShifts.length-1].cigSales.closeCount)
            gthis.drawTextRight(gthis, row-600, totalCol, 10, cigTotalVariance)
            gthis.drawTextRightCurrency(gthis, row-620, totalCol, 10, gthis.dailyShifts.map(item => item.cigaretteSaleAmount).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, row-640, totalCol, 10, gthis.dailyShifts.map(item => item.cigaretteBuyAmount).reduce((prev, next) => prev + next))

            await gthis.pageHeader(gthis)
            var lotRow = 710

            // Cash Balance
            gthis.drawColHeadLeft(gthis, lotRow-4, 30, 10, "CASH BALANCE")
            gthis.drawTextLeft(gthis, lotRow-20, 30, 10, "Cash Sales")
            await gthis.drawLine(gthis, lotRow-22)
            gthis.drawTextLeft(gthis, lotRow-40, 30, 10, "Cash Refund")
            await gthis.drawLine(gthis, lotRow-42)
            gthis.drawTextLeft(gthis, lotRow-60, 30, 10, "Cash Safe Drop")
            await gthis.drawLine(gthis, lotRow-62)            
            gthis.drawTextLeft(gthis, lotRow-80, 30, 10, "Net Lottery")
            await gthis.drawLine(gthis, lotRow-82)
            gthis.drawTextLeft(gthis, lotRow-100, 30, 10, "Cash Received - Income")
            await gthis.drawLine(gthis, lotRow-102)
            gthis.drawTextLeft(gthis, lotRow-120, 30, 10, "Cash Paid - Expense")
            await gthis.drawLine(gthis, lotRow-122)
            gthis.drawTextLeft(gthis, lotRow-140, 30, 10, "Book Balance")
            await gthis.drawLine(gthis, lotRow-142)
            gthis.drawTextLeft(gthis, lotRow-160, 30, 10, "Non-Cash in Book Balance")
            await gthis.drawLine(gthis, lotRow-162)
            gthis.drawTextLeft(gthis, lotRow-180, 30, 10, "Prior Day/Shift Cash Utilized")
            await gthis.drawLine(gthis, lotRow-182)            
            gthis.drawTextLeft(gthis, lotRow-200, 30, 10, "Actual Cash Onhand")
            gthis.drawTextLeft(gthis, lotRow-220, 30, 10, "Cash Over/Short")
            gthis.drawTotalRectangle(gthis, lotRow-220, 30, 550, 16, 0.1)
            // lotRow -= 200
                // gthis.drawTextRightCurrency(gthis, row-110, shiftCol, 10, element.cashAmount) 

            var bookBalance = 0
            var totalIncome = 0
            var totalExpense = 0
            var totalBookBalance=0
            var totalCashVariance = 0
            
            gthis.dailyShifts.forEach((item, index) => {
                bookBalance = 0
                shiftCol = index  * 85 + shiftColSpace
                var netLotterySales = item.instantLottoSales - 
                                item.instantLottoPayout + 
                                item.onlineLottoSales - 
                                item.onlineLotteryCanceled -
                                item.onlineLottoPayout +
                                item.lotteryMachineWithdraw -
                                item.lotteryNonCashSales
                gthis.drawTextRightCurrency(gthis, lotRow-20, shiftCol, 10, item.cashAmount)
                gthis.drawTextRightCurrency(gthis, lotRow-40, shiftCol, 10, item.cashRefundAmount * -1)
                gthis.drawTextRightCurrency(gthis, lotRow-60, shiftCol, 10, item.safedropCashAmount * -1)
                gthis.drawTextRightCurrency(gthis, lotRow-80, shiftCol, 10, netLotterySales)

                var shiftIncomes = 0
                item.additionalIncome.map((income) => {
            
                    if (income.paymentMethod == 'Cash') {
                        shiftIncomes += parseFloat(income.amount)
                    }

                });

                totalIncome += parseFloat(shiftIncomes)
                gthis.drawTextRightCurrency(gthis, lotRow-100, shiftCol, 10, shiftIncomes)

                var shiftExpenses = 0
                item.expenses.map((expense) => {
            
                    if (expense.payments[0].method == 'Cash') {
                        shiftExpenses += expense.payments[0].amount
                    }

                });

                totalExpense += shiftExpenses
                gthis.drawTextRightCurrency(gthis, lotRow-120, shiftCol, 10, shiftExpenses * -1)

                bookBalance = item.cashAmount - item.cashRefundAmount - item.safedropCashAmount +
                        netLotterySales + shiftIncomes - shiftExpenses
                totalBookBalance += bookBalance
                gthis.drawTextRightCurrency(gthis, lotRow-140, shiftCol, 10, bookBalance)
                gthis.drawTextRightCurrency(gthis, lotRow-160, shiftCol, 10, item.checkActualOnhand)
                gthis.drawTextRightCurrency(gthis, lotRow-180, shiftCol, 10, item.previousCashUsed)                
                gthis.drawTextRightCurrency(gthis, lotRow-200, shiftCol, 10, item.cashActualOnhand)
                var cashVariance = item.cashActualOnhand + item.checkActualOnhand - item.previousCashUsed - bookBalance  
                totalCashVariance += cashVariance
                gthis.drawTextRightCurrency(gthis, lotRow-220, shiftCol, 10, cashVariance)
            })

            gthis.drawTextRightCurrency(gthis, lotRow-20, totalCol, 10, gthis.dailyShifts.map(item => item.cashAmount).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, lotRow-40, totalCol, 10, gthis.dailyShifts.map(item => item.cashRefundAmount * -1).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, lotRow-60, totalCol, 10, gthis.dailyShifts.map(item => item.safedropCashAmount * -1).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, lotRow-80, totalCol, 10, totalNetLottery)
            gthis.drawTextRightCurrency(gthis, lotRow-100, totalCol, 10, totalIncome)
            gthis.drawTextRightCurrency(gthis, lotRow-120, totalCol, 10, totalExpense * -1)
            gthis.drawTextRightCurrency(gthis, lotRow-140, totalCol, 10, totalBookBalance)
            gthis.drawTextRightCurrency(gthis, lotRow-160, totalCol, 10, gthis.dailyShifts.map(item => item.checkActualOnhand).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, lotRow-180, totalCol, 10, gthis.dailyShifts.map(item => item.previousCashUsed).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, lotRow-200, totalCol, 10, gthis.dailyShifts.map(item => item.cashActualOnhand).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, lotRow-220, totalCol, 10, totalCashVariance)

            // Deposits
            lotRow -= 240
            gthis.drawColHeadLeft(gthis, lotRow-4, 30, 10, "DEPOSIT/DISBURSEMENTS")
            gthis.drawTextLeft(gthis, lotRow-20, 30, 10, "Bank Account - General")
            gthis.drawTextLeft(gthis, lotRow-40, 30, 10, "Bank Account - Other")
            gthis.drawTextLeft(gthis, lotRow-60, 30, 10, "Other Cash Disbursment")
            gthis.drawTextLeft(gthis, lotRow-80, 30, 10, "Cash Loaded in ATM")
            gthis.drawTextLeft(gthis, lotRow-100, 30, 10, "Total")
            gthis.drawTextLeft(gthis, lotRow-120, 30, 10, "Cash On Hand after Deposit")
            gthis.drawTotalRectangle(gthis, lotRow-100, 30, 550, 16, 0.1)
            var totalBankGen = 0
            var totalBankLot = 0
            var totalBankAtm = 0
            var totalCashLoadedInAtm = 0
            var totalCashAfterDeposit = 0
            gthis.dailyShifts.forEach((item, index) => {
                shiftCol = index  * 85 + shiftColSpace
                var shiftCashAfterDeposit = (item.safedropCashAmount + item.cashActualOnhand) - (item.bankDepositGeneral + item.bankDepositLottery + item.bankDepositAtm + item.cashLoadedInAtm)
                gthis.drawTextRightCurrency(gthis, lotRow-20, shiftCol, 10, item.bankDepositGeneral)
                gthis.drawTextRightCurrency(gthis, lotRow-40, shiftCol, 10, item.bankDepositLottery)
                gthis.drawTextRightCurrency(gthis, lotRow-60, shiftCol, 10, item.bankDepositAtm)
                gthis.drawTextRightCurrency(gthis, lotRow-80, shiftCol, 10, item.cashLoadedInAtm)
                gthis.drawTextRightCurrency(gthis, lotRow-100, shiftCol, 10, item.bankDepositGeneral + item.bankDepositLottery + item.bankDepositAtm + item.cashLoadedInAtm)
                gthis.drawTextRightCurrency(gthis, lotRow-120, shiftCol, 10, shiftCashAfterDeposit)
                totalBankGen += item.bankDepositGeneral
                totalBankLot += item.bankDepositLottery
                totalBankAtm += item.bankDepositAtm
                totalCashLoadedInAtm += item.cashLoadedInAtm 
                totalCashAfterDeposit += shiftCashAfterDeposit
            })
            gthis.drawTextRightCurrency(gthis, lotRow-20, totalCol, 10, totalBankGen)
            gthis.drawTextRightCurrency(gthis, lotRow-40, totalCol, 10, totalBankLot)
            gthis.drawTextRightCurrency(gthis, lotRow-60, totalCol, 10, totalBankAtm)
            gthis.drawTextRightCurrency(gthis, lotRow-80, totalCol, 10, totalCashLoadedInAtm)
            gthis.drawTextRightCurrency(gthis, lotRow-100, totalCol, 10, totalBankGen + totalBankLot + totalBankAtm)
            gthis.drawTextRightCurrency(gthis, lotRow-120, totalCol, 10, totalCashAfterDeposit)
        
            lotRow = lotRow - 140

            //Other Items
            gthis.drawTextLeft(gthis, lotRow-30, 30, 10, "Car Wash Sales")
            await gthis.drawLine(gthis, lotRow-32)
            gthis.drawTextLeft(gthis, lotRow-50, 30, 10, "Phone Card Sales")
            await gthis.drawLine(gthis, lotRow-52)
            gthis.drawTextLeft(gthis, lotRow-70, 30, 10, "Void Line Amount")
            await gthis.drawLine(gthis, lotRow-72)
            gthis.drawTextLeft(gthis, lotRow-90, 30, 10, "Customer Count")

            gthis.dailyShifts.forEach((element, index) => {
                shiftCol = index  * 85 + shiftColSpace
                gthis.drawColHeadLeft(gthis, lotRow-10, 30, 10, "OTHER") 
                gthis.drawTextRight(gthis, lotRow-30, shiftCol, 10, (element.carWashSold ? element.carWashSold : 0)) 
                gthis.drawTextRight(gthis, lotRow-50, shiftCol, 10, (element.phoneCardSold ? element.phoneCardSold : 0)) 
                gthis.drawTextRightCurrency(gthis, lotRow-70, shiftCol, 10, element.voidTicketAmount) 
                gthis.drawTextRight(gthis, lotRow-90, shiftCol, 10, element.customerCount)             
            })
            gthis.drawTextRight(gthis, lotRow-30, totalCol, 10, gthis.dailyShifts.map(item => item.carWashSold).reduce((prev, next) => prev + next))
            gthis.drawTextRight(gthis, lotRow-50, totalCol, 10, gthis.dailyShifts.map(item => item.phoneCardSold).reduce((prev, next) => prev + next))
            gthis.drawTextRightCurrency(gthis, lotRow-70, totalCol, 10, gthis.dailyShifts.map(item => item.voidTicketAmount).reduce((prev, next) => prev + next))
            gthis.drawTextRight(gthis, lotRow-90, totalCol, 10, gthis.dailyShifts.map(item => item.customerCount).reduce((prev, next) => prev + next))


            //Inside Lottery Sales
            lotRow -= 120
            gthis.drawColHeadLeft(gthis, lotRow, 30, 10, "LOTTERY INSIDE SALES") 
            lotRow -= 15
            var lotCount = 0
            var lotteryTotal = [
                {"count": 0, "amount": 0},
                {"count": 0, "amount": 0},
                {"count": 0, "amount": 0},
                {"count": 0, "amount": 0},
                {"count": 0, "amount": 0},
            ]
            instantLotteryArray.forEach(item => {
                gthis.drawTextLeft(gthis, lotRow, 30, 10, item.saleRackLoc  + "  " + item.gameNumber +"  "+item.bookNumber)
                shiftCol = shiftColSpace - 60
                shiftCol -= 20
                gthis.drawTextRightCurrency(gthis, lotRow, shiftCol, 10, item.pricePoint)
                shiftCol += 65
                var lotTotSaleCount = 0
                var lotDayStartingSeq = 0
                var lotDayEndingSeq = 0
                lotCount++
                shiftCol += 30
                gthis.dailyShifts.forEach((lottery,lotIndex) => {
                    if (lottery) {
                        if (item.ticketsSold[lotIndex] == null) {
                            item.ticketsSold[lotIndex] = 0
                        }
                        if (item.startingSeq[lotIndex] == null) {
                            item.startingSeq[lotIndex] = 0
                        }
                        if (item.endingSeq[lotIndex] == null) {
                            item.endingSeq[lotIndex] = 0
                        }
                        lotTotSaleCount += item.ticketsSold[lotIndex]
                        var lotSaleAmount = "$" + gthis.formatNumberWithCommas(item.ticketsSold[lotIndex] * item.pricePoint)
                        // gthis.drawTextRight(gthis, lotRow, shiftCol-15, 10, item.startingSeq[lotIndex])
                        gthis.drawTextRight(gthis, lotRow, shiftCol-15, 10, item.startingSeq[lotIndex] +'-' + item.endingSeq[lotIndex] + ' / ' + item.ticketsSold[lotIndex] + " / " + lotSaleAmount)
                        shiftCol += 85
                        lotteryTotal[lotIndex].count += item.ticketsSold[lotIndex]
                        lotteryTotal[lotIndex].amount += (item.ticketsSold[lotIndex] * item.pricePoint)
                        if (lotIndex == 0) lotDayStartingSeq = item.startingSeq[lotIndex]
                        if (item.endingSeq[lotIndex] >= lotDayStartingSeq) lotDayEndingSeq = item.endingSeq[lotIndex]                                                
                    }
                })
                var lotSaleAmount = "$" + gthis.formatNumberWithCommas(lotTotSaleCount * item.pricePoint)
                gthis.drawTextRight(gthis, lotRow, totalCol, 10, lotDayStartingSeq +'-' + lotDayEndingSeq + ' / ' + lotTotSaleCount + " / "  + lotSaleAmount )
                if (lotCount < instantLotteryArray.length) {
                    gthis.page.drawLine({
                        start: { x: 25, y: lotRow-5 },
                        end: { x: 575, y: lotRow-5 },
                        thickness: 1,
                        opacity: 0.2,
                    })
                }                
                lotRow = lotRow - 20
                if (lotRow < 50) {
                    gthis.pageHeader(gthis)
                    lotRow = 680
                }
            })
            shiftCol = shiftColSpace + 15
            gthis.dailyShifts.forEach((tot,index) =>{
                if (tot) {
                    //
                }
                gthis.drawTextRight(gthis, lotRow, shiftCol-15, 10, lotteryTotal[index].count + " / " + "$" + gthis.formatNumberWithCommas(lotteryTotal[index].amount))
                // shiftCol += 20
                shiftCol += 85
            })

            var lotteryTotalCount = lotteryTotal.map(item => item.count).reduce((prev, next) => prev + next)
            var lotteryTotalAmount = lotteryTotal.map(item => item.amount).reduce((prev, next) => prev + next)
            gthis.drawTextRight(gthis, lotRow, totalCol, 10,  lotteryTotalCount + " / " + "$" + gthis.formatNumberWithCommas(lotteryTotalAmount))
            gthis.drawTotalRectangle(gthis, lotRow, 30, 550, 16, 0.1)

            // Income
            var dayExpenses = []
            var dayIncome = []
            var dayInventory = []
            var dayFuelInventory = []

            gthis.dailyShifts.forEach((shift, index) => {
                if (shift.additionalIncome.length > 0) {
                    
                    if (lotRow < 90) {
                            gthis.pageHeader(gthis)
                            lotRow = 670
                    } else {
                        lotRow -= 40
                    }
                    gthis.drawColHeadLeft(gthis, lotRow+10, 30, 10, "OTHER INCOME - SHIFT " + (index+1))
                    var totalOtherIncome = 0
                    var incCount = 0
                    shift.additionalIncome.forEach(income => {

                        // Add income to daily 
                        dayIncome.push(income)

                        if (lotRow < 50) {
                            gthis.pageHeader(gthis)
                            lotRow = 670
                        }
                        incCount++
                        gthis.drawTextLeft(gthis, lotRow, 30, 10, gthis.toTitleCase(income.vendorName))
                        gthis.drawTextLeft(gthis, lotRow, 150, 10, gthis.toTitleCase(income.description.substring(0, 50)))
                        gthis.drawTextLeft(gthis, lotRow, 450, 10, gthis.toTitleCase(income.paymentMethod))
                        gthis.drawTextRightCurrency(gthis, lotRow, 570, 10, income.amount)
                        if (incCount < shift.additionalIncome.length) {
                            gthis.page.drawLine({
                                start: { x: 25, y: lotRow-5 },
                                end: { x: 575, y: lotRow-5 },
                                thickness: 1,
                                opacity: 0.2,
                            })
                        }
                        totalOtherIncome += income.amount
                        lotRow -= 20
                    })
                }

                if (shift.additionalIncome.length > 1) {
                    gthis.drawTextRightCurrency(gthis, lotRow, totalCol, 10, totalOtherIncome)
                    gthis.drawTotalRectangle(gthis, lotRow, 30, 550, 16, 0.1)
                }

                var fuelInventory = []
                var otherInventory = []
                var expenses = []

                shift.expenses.forEach(mapping => {
                    if (mapping.expenseTypeId == 2 && mapping.deptMapping == "Fuel") {
                        fuelInventory.push(mapping)
                        dayFuelInventory.push(mapping)                        
                    } else if (mapping.expenseTypeId == 2 && mapping.deptMapping != "Fuel") {
                        otherInventory.push(mapping)
                        dayInventory.push(mapping)
                    } else {
                        expenses.push(mapping)
                        dayExpenses.push(mapping)
                    }
                })
                

                if (fuelInventory.length > 0) {
                    if (lotRow < 90) {
                            gthis.pageHeader(gthis)
                            lotRow = 670
                    } else {
                        lotRow -= 40
                    }
                    fuelInventory = gthis.sortArrayByProp(fuelInventory, "vendorName")

                    gthis.drawColHeadLeft(gthis, lotRow+10, 30, 10, "FUEL INVOICES - SHIFT " + (index+1))
                    var totalFuelInventory = 0
                    var fuelCount = 0
                    fuelInventory.forEach(expense => {
                        if (lotRow < 90) {
                            gthis.pageHeader(gthis)
                            lotRow = 670
                        }
                        fuelCount++
                        gthis.drawTextLeft(gthis, lotRow, 30, 10, gthis.toTitleCase(expense.vendorName))
                        gthis.drawTextLeft(gthis, lotRow, 200, 10, gthis.toTitleCase(expense.expenseTypeName).substring(0, 25))
                        gthis.drawTextLeft(gthis, lotRow, 250, 10, expense.deptMapping)
                        gthis.drawTextLeft(gthis, lotRow, 315, 10, expense.payments[0].method)
                        gthis.drawTextLeft(gthis, lotRow, 350, 10, expense.payments[0].referenceNumber)                   
                        gthis.drawTextLeft(gthis, lotRow, 400, 10, gthis.toTitleCase(expense.description.substring(0, 15)))
                        gthis.drawTextRightCurrency(gthis, lotRow, 570, 10, expense.amount)
                        totalFuelInventory += expense.amount
                        if (fuelCount < fuelInventory.length) {
                            gthis.page.drawLine({
                                start: { x: 25, y: lotRow-5 },
                                end: { x: 575, y: lotRow-5 },
                                thickness: 1,
                                opacity: 0.2,
                            })
                        }
                        lotRow -= 20
    
                    })
                }
                if (fuelInventory.length > 1) {
                    gthis.drawTextRightCurrency(gthis, lotRow, totalCol, 10, totalFuelInventory)
                    gthis.drawTotalRectangle(gthis, lotRow, 30, 550, 16, 0.1)
                }                
                

                if (otherInventory.length > 0) {
                    if (lotRow < 90) {
                        gthis.pageHeader(gthis)
                        lotRow = 670
                    } else {
                        lotRow -= 40
                    }
                    otherInventory = gthis.sortArrayByProp(otherInventory, "vendorName")
                    gthis.drawColHeadLeft(gthis, lotRow+10, 30, 10, "OTHER INVENTORY PURCHASES - SHIFT " + (index+1)) 
                    var totalOtherInventory = 0
                    var oInvCount = 0
                    otherInventory.forEach(expense => {
                        if (lotRow < 150) {
                            gthis.pageHeader(gthis)
                            lotRow = 670
                        }
                        oInvCount++
                        gthis.drawTextLeft(gthis, lotRow, 30, 10, gthis.toTitleCase(expense.vendorName))
                        gthis.drawTextLeft(gthis, lotRow, 200, 10, gthis.toTitleCase(expense.expenseTypeName).substring(0, 25))
                        gthis.drawTextLeft(gthis, lotRow, 250, 10, expense.deptMapping)
                        gthis.drawTextLeft(gthis, lotRow, 315, 10, expense.payments[0].method)
                        gthis.drawTextLeft(gthis, lotRow, 350, 10, expense.payments[0].referenceNumber)                    
                        gthis.drawTextLeft(gthis, lotRow, 400, 10, gthis.toTitleCase(expense.description.substring(0, 15)))
                        gthis.drawTextRightCurrency(gthis, lotRow, 570, 10, expense.amount)
                        if (oInvCount < otherInventory.length) {
                            gthis.page.drawLine({
                                start: { x: 25, y: lotRow-5 },
                                end: { x: 575, y: lotRow-5 },
                                thickness: 1,
                                opacity: 0.2,
                            })
                        }
                        totalOtherInventory += expense.amount
                        lotRow -= 20
    
                    })                    
                }
                if (otherInventory.length > 1) {
                    gthis.drawTextRightCurrency(gthis, lotRow, totalCol, 10, totalOtherInventory)
                    gthis.drawTotalRectangle(gthis, lotRow, 30, 550, 16, 0.1)
                }                            
                
                if (expenses.length > 0) {
                    if (lotRow < 90) {
                            gthis.pageHeader(gthis)
                            lotRow = 670
                    } else {
                        lotRow -= 40
                    }
                    expenses = gthis.sortArrayByProp(expenses, "vendorName")
              
                    gthis.drawColHeadLeft(gthis, lotRow+10, 30, 10, "EXPENSES - SHIFT " + (index+1)) 
                    var totalOtherExpenses = 0
                    var expCount = 0
                    expenses.forEach(expense => {
                        if (lotRow < 50) {
                            gthis.pageHeader(gthis)
                            lotRow = 670
                        }
                        var vendName = ""
                        expCount++
                        if (expense.expenseTypeName == "Payroll") {
                            var employee = gthis.employees.find(o => o.id === expense.employeeId)
                            vendName=gthis.toTitleCase((employee.firstName+" "+employee.lastName)) 
                        } else {
                            vendName=expense.vendorName
                        }
                        gthis.drawTextLeft(gthis, lotRow, 30, 10, gthis.toTitleCase(vendName))
                        gthis.drawTextLeft(gthis, lotRow, 200, 10, gthis.toTitleCase(expense.expenseTypeName).substring(0, 25))
                        gthis.drawTextLeft(gthis, lotRow, 250, 10, expense.deptMapping)
                        gthis.drawTextLeft(gthis, lotRow, 315, 10, expense.payments[0].method)
                        gthis.drawTextLeft(gthis, lotRow, 350, 10, expense.payments[0].referenceNumber)                        
                        gthis.drawTextLeft(gthis, lotRow, 400, 10, gthis.toTitleCase(expense.description.substring(0, 15)))
                        gthis.drawTextRightCurrency(gthis, lotRow, 570, 10, expense.amount)
                        if (expCount < expenses.length) {
                            gthis.page.drawLine({
                                start: { x: 25, y: lotRow-5 },
                                end: { x: 575, y: lotRow-5 },
                                thickness: 1,
                                opacity: 0.2,
                            })
                        }                        
                        totalOtherExpenses += expense.amount
                        lotRow -= 20
    
                    }) 
                }
                if (expenses.length > 1) {
                    gthis.drawTextRightCurrency(gthis, lotRow, totalCol, 10, totalOtherExpenses)
                    gthis.drawTotalRectangle(gthis, lotRow, 30, 550, 16, 0.1)
                }                   

            })

            // Fuel Inventory
            if (gthis.groupedInventory.length > 0) {
                if (lotRow < 90) {
                    gthis.pageHeader(gthis)
                    lotRow = 670
                } else {
                    lotRow -= 20
                }
                if (gthis.groupedInventory[0][0].beginningVolumePOS > 0) {
                    if (lotRow < 50) {
                        gthis.pageHeader(gthis)
                        lotRow = 670
                    } else {
                        lotRow -= 20
                    }
                    gthis.drawColHeadLeft(gthis, lotRow+10, 30, 10, "FUEL INVENTORY - VERIFONE POS")
                    lotRow -= 10
                    gthis.drawColHeadLeft(gthis, lotRow, 30, 11, "Fuel Product")
                    gthis.drawColHeadRight(gthis, lotRow, 200, 11, "Open")
                    gthis.drawColHeadRight(gthis, lotRow, 275, 11, "Delivery")
                    gthis.drawColHeadRight(gthis, lotRow, 350, 11, "Sales")
                    gthis.drawColHeadRight(gthis, lotRow, 425, 11, "Close")
                    gthis.drawColHeadRight(gthis, lotRow, 500, 11, "Ending")
                    gthis.drawColHeadRight(gthis, lotRow, 570, 11, "Variance")

                    gthis.groupedInventory.forEach(item => {
                        if (lotRow < 50) {
                            gthis.pageHeader(gthis)
                            lotRow = 670
                        } else {
                            lotRow -= 20
                        }
                        gthis.drawTextLeft(gthis, lotRow, 30, 10, gthis.toTitleCase(item[0].product))
                        if (item[0].beginningVolumePOS > 0) {
                            gthis.drawTextRight(gthis, lotRow, 200, 10, gthis.formatNumberWithCommas(item[0].beginningVolumePOS,3))
                            gthis.drawTextRight(gthis, lotRow, 275, 10, gthis.formatNumberWithCommas(item[0].deliveredVolumePOS,3))
                            gthis.drawTextRight(gthis, lotRow, 350, 10, gthis.formatNumberWithCommas(item[0].soldVolume,3))
                            var closingVolumePOS = item[0].beginningVolumePOS + item[0].deliveredVolumePOS - item[0].soldVolume
                            gthis.drawTextRight(gthis, lotRow, 425, 10, gthis.formatNumberWithCommas(closingVolumePOS,3))
                            gthis.drawTextRight(gthis, lotRow, 500, 10, gthis.formatNumberWithCommas(item[0].endingVolumePOS,3))
                            gthis.drawTextRight(gthis, lotRow, 570, 10, gthis.formatNumberWithCommas(item[0].endingVolumePOS - closingVolumePOS,3))
                        } 
                        gthis.page.drawLine({
                            start: { x: 25, y: lotRow-5 },
                            end: { x: 575, y: lotRow-5 },
                            thickness: 1,
                            opacity: 0.2,
                        })

                    })
                }

                lotRow -= 20
                if (gthis.groupedInventory[0][0].startingVolume > 0) {
                    if (lotRow < 50) {
                        gthis.pageHeader(gthis)
                        lotRow = 670
                    } else {
                        lotRow -= 20
                    }
                    gthis.drawColHeadLeft(gthis, lotRow+10, 30, 10, "FUEL INVENTORY - VEEDER ROOT ATG")
                    lotRow -= 10
                    gthis.drawColHeadLeft(gthis, lotRow, 30, 11, "Fuel Product")
                    gthis.drawColHeadRight(gthis, lotRow, 200, 11, "Open")
                    gthis.drawColHeadRight(gthis, lotRow, 275, 11, "Delivery")
                    gthis.drawColHeadRight(gthis, lotRow, 350, 11, "Sales")
                    gthis.drawColHeadRight(gthis, lotRow, 425, 11, "Close")
                    gthis.drawColHeadRight(gthis, lotRow, 500, 11, "Ending")
                    gthis.drawColHeadRight(gthis, lotRow, 570, 11, "Variance")

                    gthis.groupedInventory.forEach(item => {
                            if (lotRow < 50) {
                                gthis.pageHeader(gthis)
                                lotRow = 670
                            } else {
                                lotRow -= 20
                            }
                            gthis.drawTextLeft(gthis, lotRow, 30, 10, gthis.toTitleCase(item[0].product))
                            if (item[0].startingVolume > 0) {
                                gthis.drawTextRight(gthis, lotRow, 200, 10, gthis.formatNumberWithCommas(item[0].startingVolume,3))
                                gthis.drawTextRight(gthis, lotRow, 275, 10, gthis.formatNumberWithCommas(item[0].deliveredVolume,3))
                                gthis.drawTextRight(gthis, lotRow, 350, 10, gthis.formatNumberWithCommas(item[0].soldVolume,3))
                                gthis.drawTextRight(gthis, lotRow, 425, 10, gthis.formatNumberWithCommas(item[0].closingVolume,3))
                                gthis.drawTextRight(gthis, lotRow, 500, 10, gthis.formatNumberWithCommas(item[0].endingVolume,3))
                                gthis.drawTextRight(gthis, lotRow, 570, 10, gthis.formatNumberWithCommas(item[0].variance,3))
                            }
                            gthis.page.drawLine({
                                start: { x: 25, y: lotRow-5 },
                                end: { x: 575, y: lotRow-5 },
                                thickness: 1,
                                opacity: 0.2,
                            })

                        })                
                }

            } else {
                if (lotRow < 80) {
                    gthis.pageHeader(gthis)
                    lotRow = 670
                } else {
                    lotRow -= 40
                }
                gthis.drawTextLeft(gthis, lotRow+10, 30, 10, " *** Fuel Inventory Information not available until day close")
            }

            // Day Income/Expense Print
            lotRow -= 20
            if (dayExpenses.length > 0) {
                if (lotRow < 90) {
                    gthis.pageHeader(gthis)
                    lotRow = 670
                } else {
                    lotRow -= 20
                }

                var expenses = gthis.sortArrayByProp(dayExpenses, "expenseTypeName")
                var groupedExpenses = []
                expenses.forEach(expense => {
                    var group = groupedExpenses.find (item => item.expenseTypeName === expense.expenseTypeName)
                    if (group) {
                        group.amount += expense.amount
                    } else {
                        group = {
                            "expenseTypeName": expense.expenseTypeName,
                            "amount": expense.amount
                        }
                        groupedExpenses.push(group)
                    }
                })
                gthis.drawColHeadLeft(gthis, lotRow+10, 30, 10, "EXPENSES - DAY SUMMARY") 
                var totalOtherExpenses = 0
                var expCount = 0
                groupedExpenses.forEach(expense => {
                    if (lotRow < 50) {
                        gthis.pageHeader(gthis)
                        lotRow = 670
                    }
                    expCount++
                    gthis.drawTextLeft(gthis, lotRow, 30, 10, gthis.toTitleCase(expense.expenseTypeName).substring(0, 25))
                    gthis.drawTextRightCurrency(gthis, lotRow, 300, 10, expense.amount)
                    if (expCount < groupedExpenses.length) {
                        gthis.page.drawLine({
                            start: { x: 25, y: lotRow-5 },
                            end: { x: 305, y: lotRow-5 },
                            thickness: 1,
                            opacity: 0.2,
                        })
                    }                        
                    totalOtherExpenses += expense.amount
                    lotRow -= 20

                }) 

                if (dayExpenses.length > 1) {
                    gthis.drawTextRightCurrency(gthis, lotRow, 300, 10, totalOtherExpenses)
                    gthis.drawTotalRectangle(gthis, lotRow, 30, 280, 16, 0.1)
                } 
            }


            // End Day Expenses

            // Day Inventory Print
            lotRow -= 20
            if (dayInventory.length > 0) {
                if (lotRow < 90) {
                    gthis.pageHeader(gthis)
                    lotRow = 670
                } else {
                    lotRow -= 20
                }

                var inventory = gthis.sortArrayByProp(dayInventory, "deptMapping")
                var groupedInventory = []
                inventory.forEach(expense => {
                    var group = groupedInventory.find (item => item.deptMapping === expense.deptMapping)
                    if (group) {
                        group.amount += expense.amount
                    } else {
                        group = {
                            "deptMapping": expense.deptMapping,
                            "amount": expense.amount
                        }
                        groupedInventory.push(group)
                    }
                })
                gthis.drawColHeadLeft(gthis, lotRow+10, 30, 10, "INVENTORY PURCHASE - DAY SUMMARY") 
                var totalDayExpenses = 0
                // var expCount = 0
                groupedInventory.forEach(expense => {
                    if (lotRow < 50) {
                        gthis.pageHeader(gthis)
                        lotRow = 670
                    }
                    expCount++
                    gthis.drawTextLeft(gthis, lotRow, 30, 10, gthis.toTitleCase(expense.deptMapping).substring(0, 25))
                    gthis.drawTextRightCurrency(gthis, lotRow, 300, 10, expense.amount)
                    if (expCount < groupedInventory.length) {
                        gthis.page.drawLine({
                            start: { x: 25, y: lotRow-5 },
                            end: { x: 305, y: lotRow-5 },
                            thickness: 1,
                            opacity: 0.2,
                        })
                    }                        
                    totalDayExpenses += expense.amount
                    lotRow -= 20

                }) 

                if (groupedInventory.length > 1) {
                    gthis.drawTextRightCurrency(gthis, lotRow, 300, 10, totalDayExpenses)
                    gthis.drawTotalRectangle(gthis, lotRow, 30, 280, 16, 0.1)
                } 
            }


            // End Day Inventory

            // Shift Notes
            
            gthis.dailyShifts.forEach((item, index) => {
                if (item.shiftNotes) {
                    if (lotRow < 100) {
                        gthis.pageHeader(gthis)
                        lotRow = 670
                    } else {
                        lotRow -= 50
                    }
                    gthis.drawTextLeft(gthis, lotRow+10, 30, 10, "NOTES - SHIFT " + (index+1))
                    gthis.drawWrappedTextLeft(gthis, lotRow+10, 130, 10, item.shiftNotes)
                }
            })

            // Create PDF Document
            const pdfDataUri = await gthis.pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },

        pageHeader: async(gthis, titleText)  => {

            if (!titleText) {
                titleText = "DAILY SUMMARY"
            }
            gthis.pageNumber++

            gthis.page = gthis.pdfDoc.addPage(PageSizes.Letter);
            
            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(storeName, 14);

            gthis.page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 765, 
                size: 14, 
                })

            var row = 745
            var reportHead = titleText
            textWidth = gthis.helveticaFont.widthOfTextAtSize(reportHead, 13);

            gthis.page.drawText(reportHead, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 15, 
                })            

            row = 730
            
            var saleDate = moment(gthis.shiftDate).format('MM/DD/YYYY')
            textWidth = gthis.helveticaFont.widthOfTextAtSize(saleDate, 11);

            gthis.page.drawText(saleDate, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 11, 
                })
            await gthis.pageFooter(gthis)                         
                
        },

        pageSubHead: async(gthis, row,subText) => {
            if (!subText) {
                subText = ""
            }
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(subText, 13);

            gthis.page.drawText(subText, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 13, 
                })   

        },

        veecliLogo: async(gthis) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await gthis.pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            gthis.page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                opacity: 0.75,
            })
            gthis.page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })                    
        },

        pageFooter: async(gthis) => {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            gthis.page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })

            var textWidth = gthis.helveticaFont.widthOfTextAtSize('Page ' + gthis.pageNumber, 9)
            gthis.page.drawText('Page ' + gthis.pageNumber, { 
                x: 590 - textWidth, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })
            await gthis.veecliLogo(gthis)
        },

        getNextRow: async(gthis,row, count) => {
  
            if (!count) count = 1
            if (row > 75) {
                return row - (5 * count)
            } else {
                await gthis.pageHeader(gthis)

                // Set Line to start records
                return 680
            }
            
        },

        drawRowHeadTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize, 
            })
            return row
        },

        drawRowTextLeft: async (gthis, row, text) => {
            row = await gthis.getNextRow(gthis,row, 2)
            if (text === "InsideSales") { text = "Inside Sales"}
            if (text === "BeerWine") { text = "Beer / Wine"}
            text = gthis.toTitleCase(text)

            gthis.page.drawText(text, { 
                x: 45, 
                y: row, 
                size: 9, 
            })
            return row
        },

        drawTextLeft: async (gthis, row, colStart, fontSize, text) => {
            if (text == null) {
                text = ""
            } else {
                text = text.toString()
            }
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawWrappedTextLeft: async (gthis, row, colStart, fontSize, text) => {
            if (text == null) {
                text = ""
            } else {
                text = text.toString()
            }
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize,
                maxWidth: 400,
                lineHeight: 14,
                TextAlignment: 2 
            })
            
        },        

        drawColHeadLeft:async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row+3, 
                size: fontSize,
                TextAlignment: 2 
            })
            gthis.page.drawLine({
                start: { x: colStart, y: row },
                end: { x: colStart+textWidth+20, y: row  },
                thickness: 1,
                opacity: 0.4,
            })
            
        },

        drawColHeadRight:async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row+3, 
                size: fontSize,
                TextAlignment: 2 
            })
            gthis.page.drawLine({
                start: { x: colStart - textWidth - 20, y: row },
                end: { x: colStart, y: row  },
                thickness: 1,
                opacity: 0.4,
            })
            
        },


        drawTextRight: async (gthis, row, colStart, fontSize, text) => {
            if (text == null) {
                text = ""
            } else {
                text = text.toString()
            }
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRightGal: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text+" gal.", fontSize)
            gthis.page.drawText(text+" gal.", { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRightCurrency: async (gthis, row, colStart, fontSize, text) => {
            if (gthis.dollarUSLocale.format(text) == "-$0.00") {
                text = 0
            }
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(gthis.dollarUSLocale.format(text), fontSize)
            gthis.page.drawText(gthis.dollarUSLocale.format(text), { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },        

        drawLine: async (gthis, row) => {
            row = await gthis.getNextRow(gthis,row, 1)
            gthis.page.drawLine({
                start: { x: 25, y: row },
                end: { x: 575, y: row },
                thickness: 1,
                opacity: 0.2,
            })
            return row
        },

        drawTotalRectangle: async (gthis, row, colStart, width, height, opacity) => {
            gthis.page.drawRectangle({
                x: colStart-4,
                y: row-4,
                width: width,
                height: height,
                rotate: degrees(0),
                borderWidth: 0,
                borderColor: grayscale(0.5),
                color: rgb(0, 0, 0),
                opacity: opacity,
                borderOpacity: 0.75
            })
        },

        async setDate() {
            this.shiftDateString = moment(this.shiftDate).format('YYYY-MM-DD')
         },

        async loadData() {
            this.user =  UserStore.getters.getUser
            await this.setDate()
                .then(() => {
                    this.fetchFuelInventory()
                        .then(() => {
                        this.fetchEmployees()                    
                        this.fetchStoreShiftSales()
                    })
                })
        }
    },
  
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>